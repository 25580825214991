'use strict';

import { SelectedNetworkFactory } from '../selectednetwork.factory';
import { SelectedOrphanFactory } from '../selectedorphan.factory';
import { AuthService } from '../dataservices/auth.service';
import { SelectedProgramFactory } from '../selectedprogram.factory';
import { SelectedActivityFactory } from '../selectedactivity.factory';
import { SelectedSquareFactory } from '../selectedsquare.factory';

export class HelperService {

  static $inject = ['$state', '$stateParams', 'selectedSquareFactory',
    'selectedOrphanFactory', 'selectedActivityFactory', 'selectedNetworkFactory', 'selectedProgramFactory',
    'authService', '__env', '$window'];

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private selectedSquareFactory: SelectedSquareFactory,
    private selectedOrphanFactory: SelectedOrphanFactory,
    private selectedActivityFactory: SelectedActivityFactory,
    private selectedNetworkFactory: SelectedNetworkFactory,
    private selectedProgramFactory: SelectedProgramFactory,
    private authService: AuthService,
    private __env: Environment,
    private $window: ng.IWindowService,
  ) { }

  entityTypeMap: { [entityType in TEntityType]: IEntityTypeDefinition } = {
    network: {
      pageToRedirectTo: 'root.networkinfo',
      selectedEntityFactory: this.selectedNetworkFactory,
      customizeRedirectTargetForEntity: (redirectTarget, entity) => ({
        stateName: redirectTarget.stateName,
        stateParams: {
          ...redirectTarget.stateParams,
          networkGuid: entity.Guid,
        },
      }),
    },
    program: {
      pageToRedirectTo: 'root.programinfo',
      selectedEntityFactory: this.selectedProgramFactory,
      customizeRedirectTargetForEntity: (redirectTarget, entity) => ({
        stateName: redirectTarget.stateName,
        stateParams: {
          ...redirectTarget.stateParams,
          programGuid: entity.Guid,
        },
      }),
    },
    square: {
      pageToRedirectTo: 'root.square.activities',
      selectedEntityFactory: this.selectedActivityFactory,
      customizeRedirectTargetForEntity: (redirectTarget, entity) => ({
        stateName: entity.IsCompleted ? redirectTarget.stateName : 'root.square.info',
        stateParams: {
          ...redirectTarget.stateParams,
          squareGuid: entity.Guid,
          clonedSquareGuid: entity.ClonedSquareGuid,
        },
      }),
    },
    orphan: {
      pageToRedirectTo: 'root.orphaninfo',
      selectedEntityFactory: this.selectedOrphanFactory,
      customizeRedirectTargetForEntity: (redirectTarget, entity) => ({
        stateName: redirectTarget.stateName,
        stateParams: {
          ...redirectTarget.stateParams,
          orphanGuid: entity.Guid,
        },
      }),
    },
    activity: {
      pageToRedirectTo: 'root.square.activity',
      selectedEntityFactory: this.selectedActivityFactory,
      customizeRedirectTargetForEntity: (redirectTarget, entity) => ({
        stateName: redirectTarget.stateName,
        stateParams: {
          ...redirectTarget.stateParams,
          activityGuid: entity.Guid,
        },
      }),
    },
  };

  search(filter, modelFilter, strConv = null) {
    return (item) => {
      for (const filterItem of filter) {
        let propertyVal = item[filterItem];
        if (propertyVal !== null && typeof propertyVal !== 'undefined') {
          if (strConv) {
            propertyVal = strConv(filterItem, propertyVal);
          }
          propertyVal = (propertyVal.toString()).toLowerCase();
          if (!modelFilter || (propertyVal.indexOf(modelFilter.toLowerCase()) !== -1)) {
            return true;
          }
        }
      }
      return false;
    };
  }

  // Redirects to the page specified by the pageToRedirectTo param
  goToEntity(entityType: TEntityType, entity, customRedirectTarget: IRedirectTarget = {}) {
    const { pageToRedirectTo, customizeRedirectTargetForEntity } = this.entityTypeMap[entityType];
    let redirectTarget: IRedirectTarget = {
      stateName: pageToRedirectTo,
      stateParams: {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      },
    };

    redirectTarget = customizeRedirectTargetForEntity(redirectTarget, entity);
    if (customRedirectTarget.stateName) {
      redirectTarget.stateName = customRedirectTarget.stateName;
    }
    if (customRedirectTarget.stateParams) {
      redirectTarget.stateParams = {
        ...redirectTarget.stateParams,
        ...customRedirectTarget.stateParams,
      };
    }

    this.$state.go(redirectTarget.stateName, redirectTarget.stateParams);
  }

  public async goToSquareParticipantView(squareUrl, squareToken?: string) {
    if (!squareUrl) {
      return;
    }
    const redirectUrl = this.getSquareParticipantRedirectUrlBase(squareUrl, squareToken);

    this.$window.open(redirectUrl, '_blank');
  }

  private getSquareParticipantRedirectUrlBase = (squareUrl, squareToken?: string) => {
    // compose the URL properly

    // use interface URL to take the protocol (cannot assume http: or https:)
    const interfaceUrl = new URL(this.__env.interfaceUrl);

    // token
    const userToken = squareToken ?? this.authService.getToken();

    // square URL base
    const redirectUrl = new URL(`${interfaceUrl.protocol}//${squareUrl}.${this.__env.squareUrlBase}`);
    redirectUrl.pathname = 'sso';

    // change path to sso and use token and from_admin as query arguments
    if ((this.authService.isObserverUserLogin() && (squareToken || !this.selectedSquareFactory || !this.selectedSquareFactory.Guid))
      || this.authService.isModeratorUserLogin()
      || this.authService.isInSitesUserLogin()) {
      // IMPORTANT!
      // always use from_admin = 1 when going from management module to the member portal's sso
      redirectUrl.searchParams.append('from_admin', '1');
    } else if (this.authService.isObserverUserLogin()) {
      redirectUrl.searchParams.append('from_observer', '1');
    }

    redirectUrl.searchParams.append('token', userToken);

    if (this.authService.isImpersonating) {
      redirectUrl.searchParams
        .append('on_behalf_of', this.authService.impersonate.SquareParticipantGuid);
    }
    return redirectUrl.toString();
  };

  public async goToSquareParticipantForumView(squareUrl, forumGuid, redirectType = null) {
    if (this.isNullOrEmpty(squareUrl) || this.isNullOrEmpty(forumGuid)) {
      return;
    }

    let redirectUrl = this.getSquareParticipantRedirectUrlBase(squareUrl);
    redirectUrl += `${redirectUrl.charAt(redirectUrl.length - 1) === '/'
      ? '?'
      : '&'}go_to_conversation=${forumGuid}`;
    if (redirectType) {
      redirectUrl += `&redirect_type=${redirectType}`;
    }
    this.$window.open(redirectUrl, '_blank');
  }

  public async goToHangfire() {
    if (this.authService.isInSitesUserLogin()) {
      let redirectUrl = `${this.__env.commandApiUrl}/hangfire`;
      redirectUrl += `/?token=${this.authService.getToken()}`;
      this.$window.open(redirectUrl, '_blank');
    }
    return false;
  }

  isNullOrEmpty(checkedString) {
    return checkedString === null || checkedString === undefined || checkedString === '';
  }
}

type TEntityType = 'square' | 'activity' | 'network' | 'orphan' | 'program';

interface IEntityTypeDefinition {
  selectedEntityFactory: any,
  pageToRedirectTo: string,
  customizeRedirectTargetForEntity: (redirectTarget: IRedirectTarget, entity: any) => IRedirectTarget,
}

interface IRedirectTarget {
  stateName?: string,
  stateParams?: any,
}
