'use strict';

import { Utils } from './../../../../../core/utils';
import { ParticipantService } from './../../../../../core/dataservices/participant.service';
import * as _ from 'lodash';
import { SquareService } from '../../../../../core/dataservices/square.service';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { ServerConstants } from '../../../../../core/serverconstants';
import { CurrentUserService } from '../../../../../core/dataservices/currentUser.service';
import { AuthService } from '../../../../../core/dataservices/auth.service';
import { ConstantsFactory } from '../../../../../core/constants.factory';
import { IScope } from 'angular';
import { SuspendMembersController } from '../../memberActionBar/suspend-members.controller';
import { AccountDetails, IChangeSuspendStatusRequest, IMemberDetail } from '../../../../../core/contracts/member.contract';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
const suspendMemberTemplate = require('../../memberActionBar/suspend-members.html');

export class MemberDetailsProfileController {
  static $inject = [
    '$stateParams',
    '$mdDialog',
    'authService',
    'participantservice',
    'serverConstants',
    'logger',
    'squareservice',
    '$timeout',
    '$q',
    'spinnerservice',
    'currentUserService',
    'constantsfactory',
    '$scope',
    'featureservice',
    '__env',
  ];

  private initial;
  private isFormValid = false;
  private formDataSaved = false;
  member: IMemberDetail;
  countries = [];
  roles = [];
  isSaving = false;
  editMemberForm: ng.IFormController;
  navigationErrorMessage: string;
  validationConstants;
  isMemberActivated: boolean = false;
  suspendReasons = [];
  needsComment = false;
  showLockedSwitch = false;

  isDiySquare = false;
  isAzureAdB2CLoginEnabled = false;

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private $mdDialog: ng.material.IDialogService,
    private authService: AuthService,
    private participantservice: ParticipantService,
    private serverConstants: ServerConstants,
    private logger: Logger,
    private squareservice: SquareService,
    private $timeout: ng.ITimeoutService,
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private currentUserService: CurrentUserService,
    private constantsFactory: ConstantsFactory,
    private $scope: IScope,
    private featureService: FeatureService,
    private __env: Environment,
  ) {
    this.validationConstants = this.serverConstants.validationConstants;
  }

  async $onInit() {
    this.isAzureAdB2CLoginEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.azureAdB2CLogin);

    const promise = this.$q.all([this.loadCountryList() as any, this.loadMember()]).then(() => {
      this.$timeout(() => {
        this.isFormValid = this.editMemberForm?.$valid !== undefined
          ? this.editMemberForm.$valid
          : true;
      });
    });
    this.spinnerservice.showFor('loading', promise);

    await this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((data) => this.isDiySquare = data.data.Detail.JobIds.some((job) => job.IsSelfServe));

    const roleConstants = this.serverConstants.roleConstants;
    const sortingArr = [roleConstants.human8, roleConstants.professionalAdmin, roleConstants.clientAdmin,
      roleConstants.clientEditor, roleConstants.observer, roleConstants.participant];

    this.roles = _.filter(Utils.getRoleEnumAsArray(roleConstants), (role) =>
      sortingArr.includes(role.Value),
    );

    // Sort roles based on their order in sortingArr
    this.roles.sort((a, b) => sortingArr.indexOf(a.Value) - sortingArr.indexOf(b.Value));

    // Filter out roles based on the condition of `isDiySquare`
    if (!this.isDiySquare) {
      this.roles = this.roles.filter((role) => role.Value !== roleConstants.clientAdmin && role.Value !== roleConstants.clientEditor);
    }

    this.suspendReasons =
      _.compact(
        Object
          .values(this.serverConstants.suspendReasonConstants)
          .map((reason) => {
            const labelData = this.constantsFactory.getSuspendReasonsForSelection(reason);
            if (labelData) {
              return { index: reason, label: labelData.label, needsComment: labelData.needsComment };
            }
          }),
      );

    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        if (!isEnabled) {
          this.suspendReasons = this.suspendReasons.filter((s) => s.index !== 11);
        }
      });

    this.$scope.$watch('vm.member.SuspendRequest.SuspendReason', () => {
      if (this.member?.SuspendRequest?.SuspendReason) {
        const reason = this.suspendReasons.find((s) => s.index === this.member?.SuspendRequest.SuspendReason);
        this.needsComment = (this.member?.SuspendRequest.ShouldSuspend && reason?.needsComment) || false;
      }
    });
  }

  async loadMember() {
    const participant = await this.participantservice.getSquareParticipantDetails(this.$stateParams.memberGuid);
    // Sets about to default value of textarea to avoid view updating prematurely if About is null (bug 53466)
    participant.data.Detail.About = participant.data.Detail.About || '';
    // Add SuspendAction data to model for easier mapping
    const mappedParticipantData = participant.data.Detail;

    const suspendData: IChangeSuspendStatusRequest = {
      ShouldSuspend: participant.data.SuspendAction?.IsSuspend ?? false, // Resolve undefined to false
      SuspendReason: participant.data.SuspendAction?.SuspendReason,
      Comment: participant.data.SuspendAction?.Comment,
    };
    mappedParticipantData.SuspendRequest = suspendData;

    const accType = Utils.getSentenceCasedLabel(this.serverConstants.accountTypeConstants,
      participant.data.AccountTypeAndSocialLoginType
        ? participant.data.AccountTypeAndSocialLoginType.AccountType
        : this.serverConstants.accountTypeConstants.squareAccount);
    const accountDetails: AccountDetails = {
      AccountType: accType,
      SocialLoginType: Utils.getSentenceCasedLabel(this.serverConstants.socialLoginTypeConstants,
        participant.data.AccountTypeAndSocialLoginType
          ? participant.data.AccountTypeAndSocialLoginType.SocialLoginType
          : this.serverConstants.socialLoginTypeConstants.none),
    };
    mappedParticipantData.AccountDetails = accountDetails;

    this.showLockedSwitch = mappedParticipantData.Locked;

    this.initial = angular.copy(mappedParticipantData);
    this.member = angular.copy(mappedParticipantData);
    this.checkMemberActivated(this.member.Status);

    // Needed to make sure the form doesn't prematurely set to dirty (bug 53466)
    this.editMemberForm.$setPristine();
    this.editMemberForm.$setUntouched();
  }

  async loadCountryList() {
    this.countries = await this.squareservice.getSquareCountries(this.$stateParams.squareGuid);
  }

  editMember() {
    this.isSaving = true;

    if (!this.needsComment) {
      this.member.SuspendRequest.Comment = null; // Clears Comment if not needed
    }

    return this.participantservice.updateMemberDetail(this.member)
      .then(() => {
        this.logger.success('Member update successfully');
        if (this.editMemberForm) {
          this.editMemberForm.$setPristine();
        }
        this.initial = angular.copy(this.member);

        if (!this.initial.Locked) {
          this.showLockedSwitch = false;
        }
        this.formDataSaved = true;
      }, (error) => {
        if (error.status === 400) {
          const data = error.data;
          if (data) {
            if (data.ValidationErrors) {
              // Group by property name in case there is more than 1 error for that property
              // Ideally we should already group them in the backend
              const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
              _.forEach(grouped, (item, key) => {
                let message = '';
                _.forEach(item, (errorMessage) => {
                  message += `${(errorMessage as any).ErrorMessage} `;
                });
                this.editMemberForm[key].$setValidity('serverErrors', false);
                this.editMemberForm[key].errorMessage = message;
              });
            } else if (data.Message) {
              this.logger.error(data.Message);
            }
          }
        }
      }).finally(() => {
        this.isSaving = false;
      });
  }

  rollBackForm() {
    this.member = angular.copy(this.initial);
    this.editMemberForm.$setUntouched();
    this.editMemberForm.$setPristine();
  }

  validateNavigation(toParams, fromParams) {
    const result = {
      canNavigate: true,
      isDirty: false,
      navigationErrorMessage: '',
    };
    if (this.editMemberForm.$dirty) {
      if (!this.editMemberForm.$valid && this.isMemberActivated && this.member.Username === undefined) {
        let msg = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';

        if (!this.editMemberForm.Username.$valid) {
          msg += ':\nUsername can\'t contain parts of your full name';
        }

        this.navigationErrorMessage = msg;
        result.navigationErrorMessage = msg;
        result.canNavigate = false;
      } else if (!this.isFormValid && this.editMemberForm.$valid && !this.formDataSaved) {
        if (toParams !== undefined && fromParams !== undefined && toParams.activityGuid === fromParams.activityGuid) {
          this.navigationErrorMessage = 'Please save the changes';
          result.canNavigate = false;
        }
      }
      result.isDirty = this.editMemberForm.$dirty;
    }
    return result;
  }

  async okToSuspend() {
    if (!this.member.SuspendRequest.ShouldSuspend) {
      await this.$mdDialog.show(
        {
          controller: SuspendMembersController,
          controllerAs: 'vm',
          templateUrl: suspendMemberTemplate,
          clickOutsideToClose: false,
          locals: {
            model: { SuspendReason: this.member.SuspendRequest.SuspendReason, Comment: this.member.SuspendRequest.Comment },
            suspendReasons: this.suspendReasons,
            displayText: `Are you sure you want to suspend ${this.fullname()}?`,
          },
        },
      ).then((reason: { SuspendReason: number, Comment?: string }) => {
        this.member.SuspendRequest.SuspendReason = reason.SuspendReason;
        this.updateSuspendReason();
        if (this.needsComment) {
          this.member.SuspendRequest.Comment = reason.Comment;
        }
      }, () => {
        this.member.SuspendRequest.ShouldSuspend = false;
      });
    } else {
      this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title('Unsuspend member')
          .text(`Are you sure you want to unsuspend ${this.fullname()}?`)
          .ok('Yes')
          .cancel('No'),
      ).then(() => { // If confirmed, clear data
        this.member.SuspendRequest.SuspendReason = null;
        this.member.SuspendRequest.Comment = null;
        this.needsComment = false;
      }, () => {
        this.member.SuspendRequest.ShouldSuspend = true; // Reset back to suspended, suspend data is still present
      });
    }
  }

  updateSuspendReason() {
    const reason = this.suspendReasons.find((s) => s.index === this.member?.SuspendRequest.SuspendReason);
    this.needsComment = this.member?.SuspendRequest.ShouldSuspend && reason.needsComment;
  }

  isMemberBasicInputDisabled() {
    return this.initial &&
      (this.initial.Role === this.serverConstants.roleConstants.human8
        || (this.__env.useSsoLoginPage && // If using the new SSO login page, can't change roles except between moderator & observer
       (this.initial.Role === this.serverConstants.roleConstants.professionalAdmin || this.initial.role === this.serverConstants.roleConstants.clientAdmin
          || this.initial.role === this.serverConstants.roleConstants.clientEditor || this.initial.Role === this.serverConstants.roleConstants.observer)
        )
        || (this.initial.Guid !== this.currentUserService.userProfile.Guid && this.initial.Role === this.currentUserService.userProfile.Role));
  }

  isInSitesUser() {
    return this.currentUserService.userProfile.Role === this.serverConstants.roleConstants.human8;
  }

  canUserChangeUsername() {
    // Human8 users can still change their own usernames
    return this.isInSitesUser() && this.initial.Guid === this.currentUserService.userProfile.Guid;
  }

  isRoleDisabled(role) {
    if (this.initial
      && (this.__env.useSsoLoginPage || this.isAzureAdB2CLoginEnabled)
      && role.Value === this.serverConstants.roleConstants.participant
      && this.initial.Role !== this.serverConstants.roleConstants.participant) {
      return true;
    }
    const oldRoleConditions =
      this.initial &&
      (this.initial.Role === this.serverConstants.roleConstants.human8
        || this.initial.Role === this.currentUserService.userProfile.Role
        || role.Value === this.serverConstants.roleConstants.human8
        || ((role.Value === this.serverConstants.roleConstants.professionalAdmin || role.Value === this.serverConstants.roleConstants.clientAdmin
          || role.Value === this.serverConstants.roleConstants.clientEditor)
          && this.currentUserService.userProfile.Role !== this.serverConstants.roleConstants.human8));

    // Human8 users, moderators and observers will have their accounts in Azure AD so changing to and from participant can't be done here
    // This behaviour for moderators & observers are behind an env variable
    const roleConditionsForAdLogin =
      this.initial &&
      (this.initial.Role === this.serverConstants.roleConstants.participant
        || role.Value === this.serverConstants.roleConstants.participant);

    // If neither moderators&observers (useSsoLoginPage) and participants (isAzureADB2CLogin) use Azure, you can swap between them
    // If both are enabled, you can only upgrade to moderator/observer from participant
    // If only 1 is enabled, you cant swap between them at all
    return oldRoleConditions || (this.__env.useSsoLoginPage !== this.isAzureAdB2CLoginEnabled && roleConditionsForAdLogin);
  }

  isMemberSuspendAvailable() {
    let result = this.initial &&
      ((this.currentUserService.userProfile.Role === this.serverConstants.roleConstants.human8 &&
        this.initial.Role === this.serverConstants.roleConstants.professionalAdmin) ||
        this.initial.Role === this.serverConstants.roleConstants.clientAdmin ||
        this.initial.Role === this.serverConstants.roleConstants.clientEditor ||
        this.initial.Role === this.serverConstants.roleConstants.participant ||
        this.initial.Role === this.serverConstants.roleConstants.observer);
    if (!result) {
      result = this.authService.isDeveloper()
        || this.authService.isTeamLead();
    }
    return result;
  }

  fullname() {
    return this.member.FirstName + (this.member.LastName !== '' ? ` ${this.member.LastName}` : '');
  }

  checkMemberActivated(status): void {
    if (status === this.serverConstants.registrationStatusConstants.active) {
      this.isMemberActivated = true;
    }
  }

  isSaveDisabled() {
    return !this.editMemberForm.$dirty || this.isSaving || (!this.editMemberForm.$valid && this.isMemberActivated && this.member.Username === undefined);
  }
}
