'use strict';

import { AuthService } from './auth.service';
import { RouterHelper } from '../../blocks/router/router-helper';

export class InsitesUserDetailService {
  static $inject = ['$mdDialog', 'authService'];
  constructor(
    private $mdDialog,
    private authService: AuthService,
  ) {
  }


  async hasInSitesUserAccess(clientGuid, squareGuid = null): Promise<boolean> {
    if (this.authService.isInSitesUserLogin()) {
      const hasInSitesUserAccess = await this.authService.hasInSitesUserAccess(clientGuid, squareGuid);
      if (!hasInSitesUserAccess) {
        await this.showLimitedAccessDialog(squareGuid);
        return false;
      }
    }
    return true;
  }

  async showLimitedAccessForInsitesUser(squareGuid): Promise<boolean> {
    if (this.authService.isInSitesUserLogin()) {
      this.showLimitedAccessDialog(squareGuid).catch(() => {
        window.location.href = '/';
      });

      return true;
    }

    return false;
  }

  private showLimitedAccessDialog(squareGuid) {
    // If the Human8 user is invalid, we showed the dialog already
    if (squareGuid && RouterHelper.insitesUserInvalidSquareGuid === squareGuid) {
      return;
    }

    let firstText = 'Client and its Square(s)';
    let secondText = 'Client';
    if (squareGuid) {
      firstText = 'Square';
      secondText = 'Square';
      RouterHelper.insitesUserInvalidSquareGuid = squareGuid;
    }
    // We set up the Human8 user invalid here so that our default state error handler to redirect to home
    const dialog = this.$mdDialog.iscConfirm()
      .text(`The access to this ${firstText} is limited.\n\nPlease contact the project owner - this can be either ` +
        `the CST lead or the PST lead - giving a clear description WHY you need access to WHICH Square(s) and WHO referred you to this ${secondText}.\n\n` +
        'This will make it easier for the project owner to assess your request.\n\nAlternatively, a ticket can be added through the Service Desk.')
      .cancel('Ok');
    return this.$mdDialog.show(dialog);
  }
}
