'use strict';

import { AuthService } from '../../core/dataservices/auth.service';
import { ISavingUpModel } from './squareInfoChangeConfirm/savingUpModel';
import { ProjectService } from '../../core/dataservices/project.service';
import { IUpdateFeatureSettingsRequest } from '../../core/contracts/feature.contracts';
import { FeatureService } from '../../core/dataservices/feature.service';
import { SquareService } from '../../core/dataservices/square.service';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { ServerConstants } from '../../core/serverconstants';
import { DateFormatService } from '../../core/services/dateformat.service';
import { SquareInfoChangeService } from './squareInfoChangeConfirm/squareinfochange.service';
import { SquareOpenRegistrationService } from './squareOpenRegistration/squareOpenRegistration.service';
import { SquareChangeType } from './squareInfoChangeConfirm/squareChangeType';
import { ClipboardService } from '../../core/services/clipboard.service';
import { ProgramService } from '../../core/dataservices/program.service';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { IJobId } from '../../core/dataservices/square.service.contracts';
import { Utils } from '../../core/utils';
import { ActivityService } from '../../core/dataservices/activity.service';
import { IScreenerSurveysForOpenRegistrationResponse } from './../../core/dataservices/dataservice.contracts.d';

export class SquareInfoController {
  static $inject =
    ['$stateParams', 'logger', 'squareservice', '$q', 'serverConstants', 'ClipboardService',
      'spinnerservice', '__env', 'dateFormatService', 'projectservice', 'selectedSquareFactory',
      '$timeout', 'featureservice', 'squareInfoChangeService', 'authService', 'programservice', '$mdDialog','activityservice',
      'squareOpenRegistrationService', '$state'];
  constructor(
    private $stateParams,
    private logger,
    private squareservice: SquareService,
    private $q,
    private serverConstants: ServerConstants,
    public clipboardService: ClipboardService,
    private spinnerservice,
    private __env,
    private dateFormatService: DateFormatService,
    private projectservice: ProjectService,
    private selectedSquareFactory: SelectedSquareFactory,
    private $timeout,
    private featureService: FeatureService,
    private squareInfoChangeService: SquareInfoChangeService,
    private authService: AuthService,
    private programservice: ProgramService,
    private $mdDialog: ng.material.IDialogService,
    private activityservice: ActivityService,
    private squareOpenRegistrationService: SquareOpenRegistrationService,
    private $state,
  ) {
    this.model.detail = {
      Name: '',
      Url: '',
      JobIds: [] as IJobId[],
      Countries: [],
      StartDate: this.dateFormatService.getDateTime(this.minDate, this.currentTimeZone),
      StartTime: this.dateFormatService.getDateTime(this.minDate, this.currentTimeZone),
      StartDateOffset: this.currentTimeZone,
      ClonedSquareGuid: this.$stateParams.clonedSquareGuid,
      DigestEmailOffset: this.currentTimeZone,
      IsDigestEmailEnabled: true,
      DigestMailTime: DateTime.now().startOf('day').plus({ days: 1 }),
      ClosureMessage: undefined,
      Status: undefined,
      FeatureSettings: [],
      PasswordLength: null,
      HasStrongPasswordEnabled: false,
      AdLandingPage: false,
    };
    this.activate();
  }

  squareJobStatusFilter = [this.serverConstants.jobIdStatusConstants.active, this.serverConstants.jobIdStatusConstants.notStarted,
    this.serverConstants.jobIdStatusConstants.paused, this.serverConstants.jobIdStatusConstants.elapsed75Perc,
    this.serverConstants.jobIdStatusConstants.elapsed90Perc];
  existingJobIds: string[] = [];
  minDate = this.dateFormatService.startOfDay();
  defaultDate = DateTime.now().plus({ days: 7 });
  jobIds: string[] = [];
  isFormValid = false;
  formDataSaved = false;
  initial;
  model: any = {};
  validationConstants = this.serverConstants.validationConstants;
  languages = {};
  screeners: IScreenerSurveysForOpenRegistrationResponse[] = [];
  selectedScreener: IScreenerSurveysForOpenRegistrationResponse;
  hasStarted = false;
  urlBase = this.__env.squareUrlBase;
  navigationErrorMessage = '';
  isSaving;
  squareInfo;
  disabledSquareUrls = [];
  isBindingCreated: boolean = true;
  ssoIv;
  ssoKey;
  isOpenRegistrationEnabled: boolean;
  hasOpenRegistrationToggleChanged: boolean = false;
  hasOpenRegistrationScreenerChanged: boolean = false;
  isfetchingSsoCodes;
  checkMarketMasterSwitchEnabled: boolean;
  checkMarketEnabled: boolean;
  checkMarketMasterKey: string;
  checkMarketKey: string;
  savingUpRewardsEnabled: boolean;
  countries = [];
  initialCountries;
  currencies = [];
  countryNames = [];
  infoForModal = []; // Dictionary
  configuredSavingUpRewardCurrencies: string[];
  euroCurrencyGuid: string;
  saveCallback = () => this.updateSquare(true);
  squareStatus: number;
  currentTimeZone = DateTime.local().offset / 60;
  warningMessage = null;
  jobIdReminderEmail: string | null;
  initialJobIdReminderEmail: string | null;
  creditsReminderEmail: string | null;
  initialCreditsReminderEmail: string | null;
  isInSitesUser: boolean;
  isFullDevUser = false;
  selectedJobIds: [string];
  showVerifyItsYouSettings = false;

  activate() {
    this.logger.info('Square details activated');
    this.spinnerservice.show('loading');

    this.isInSitesUser = this.authService.isInSitesUserLogin();
    this.isFullDevUser = this.authService.isFullDev();

    const promisesOptions = [
      this.getLanguageList(),
      this.getJobIds(),
      this.getSquareCountries(),
      this.getCurrencies(),
      this.getIsSavingUpRewardsEnabled(),
      this.getReminderEmails(),
      this.getScreenerSurveysForOpenRegistration(),
    ];

    this.$q.all(promisesOptions).then(() => {
      this.logger.info('Options for language, jobId and country loaded');

      const promisesSquareDetails = [this.getSquareDetails(), this.getSquareFeatureSettings()];

      this.$q.all(promisesSquareDetails).then(() => {
        this.logger.info('Square details loaded');
        if (this.model.detail.HasActivities && this.savingUpRewardsEnabled) {
          this.warningMessage = 'Job ID may be in use in Activities where Saving Up rewards is configured, removal can impact the budget. ' +
            'It will be replaced with the most recent Job ID available. Please revise the link between this Job ID and the corresponding Activities if needed.';
        }

      }).finally(() => {
        this.spinnerservice.hide('loading');
        // The 2 lines below stop the jobStatusFilter from making the form dirty.
        this.squareInfo.jobStatusFilter.$pristine = false;
        this.squareInfo.jobStatusFilter.$setPristine = _.noop;
        this.$timeout(() => {
          this.isFormValid = this.squareInfo.$valid;
        });
      });
    });
  }

  getReminderEmails() {
    // Only Human8 Users can access and change this data
    if (!this.isInSitesUser) {
      return;
    }

    return this.programservice.getProgramDetails(this.$stateParams.programGuid)
      .then((response) => {
        this.jobIdReminderEmail = response.Detail.JobIdReminderEmail;
        this.initialJobIdReminderEmail = response.Detail.JobIdReminderEmail;
        this.creditsReminderEmail = response.Detail.CreditsReminderEmail;
        this.initialCreditsReminderEmail = response.Detail.CreditsReminderEmail;
      });
  }

  hasSquareOnlyExpiredJobIds() {
    if(this.model.detail.Status === this.serverConstants.squareStatusConstants.active && this.model.detail.Status !== this.initial.Status) {
      return this.model.detail.JobIds.every((jobId) => jobId.Status === this.serverConstants.jobIdStatusConstants.expired);
    }
    return false;
  }

  getCurrencies() {
    return this.projectservice.getCurrencies()
      .then((data) => {
        this.currencies = data.List;
        this.euroCurrencyGuid = this.currencies.find((c) => c.Name.toUpperCase() === 'EUR').Guid;
      });
  }

  async updateSquare(saveAsDraft = false) {
    const savingUpModel = await this.getSavingUpModelWithNewCurrencies();
    const shouldConfirmChangesList = await this.updateSquareConfirm(savingUpModel);

    if (shouldConfirmChangesList.length > 0) {
      const updateConfirmed = await this.squareInfoChangeService.showConfirmationMessage(
        shouldConfirmChangesList,
        this.squareInfo.Name.$modelValue,
        this.squareInfo.ClosureMessage ? this.squareInfo.ClosureMessage.$modelValue : null, savingUpModel);
      if (!updateConfirmed) {
        return;
      }
    }
    this.isSaving = true;
    const savingData = angular.copy(this.model.detail);

    savingData.SaveAsDraft = saveAsDraft && this.squareStatus === this.serverConstants.squareStatusConstants.draft;

    // Check if element contains text Or image
    const div = document.createElement('div');
    if (savingData.WelcomeBodyResearcher) {
      div.innerHTML = savingData.WelcomeBodyResearcher;
      savingData.WelcomeBodyResearcher =
        this.doesElementContainTextOrImage(div) ? savingData.WelcomeBodyResearcher : null;
    }
    if (savingData.WelcomeBodyObserver) {
      div.innerHTML = savingData.WelcomeBodyObserver;
      savingData.WelcomeBodyObserver =
        this.doesElementContainTextOrImage(div) ? savingData.WelcomeBodyObserver : null;
    }
    if (savingData.WelcomeBodyParticipant) {
      div.innerHTML = savingData.WelcomeBodyParticipant;
      savingData.WelcomeBodyParticipant =
        this.doesElementContainTextOrImage(div) ? savingData.WelcomeBodyParticipant : null;
    }
    if (savingData.StartDate && savingData.StartDateOffset) {
      savingData.StartDate = this.dateFormatService.getDateTimeForSaving(savingData.StartDate, savingData.StartDateOffset);
    }
    if (savingData.DigestMailTime && savingData.DigestEmailOffset) {
      savingData.DigestMailTime = this.dateFormatService.getDateTimeForSaving(savingData.DigestMailTime, savingData.DigestEmailOffset);
    }
    if (this.isOpenRegistrationEnabled) {
      savingData.OpenRegistrationScreenerSurvey = this.selectedScreener.activityGuid;
    }

    const checkMarketFeatureSettings: IUpdateFeatureSettingsRequest = {
      Settings: {},
      Enabled: this.checkMarketEnabled,
      Feature: this.serverConstants.featureConstants.checkMarket,
    };

    // Only send the settings to the backend to be updated if the feature is enabled
    if (this.checkMarketEnabled) {
      checkMarketFeatureSettings.Settings[this.serverConstants.featureSettingKeysConstants.checkMarketMasterKey] = this.checkMarketMasterKey;
      checkMarketFeatureSettings.Settings[this.serverConstants.featureSettingKeysConstants.checkMarketKey] = this.checkMarketKey;
    }
    savingData.FeatureSettings = [];
    savingData.FeatureSettings.push(checkMarketFeatureSettings);
    savingData.Countries = this.model.Countries.map((x) => x.Iso);

    this.updateSquareInternal(savingData, this.jobIdReminderEmail);
    this.featureService.invalidateFeatureSettings(this.serverConstants.featureConstants.checkMarket);
    this.featureService.invalidateFeatureSettings(this.serverConstants.featureConstants.dividedDiscussionTypes);
    this.featureService.invalidateFeatureSettings(this.serverConstants.featureConstants.savingUpRewards);
  }

  private async updateSquareConfirm(savingUpModel: ISavingUpModel) {
    const shouldConfirmChangesList = [];
    if (this.squareInfo.Url.$dirty && this.model.detail.Status === this.serverConstants.squareStatusConstants.active) {
      const index = this.disabledSquareUrls.indexOf(this.model.detail.Url);
      shouldConfirmChangesList.push(index > -1 ? SquareChangeType.ChangeUrlToPrevious : SquareChangeType.ChangeUrl);
    }

    if (this.model.detail.Status !== this.initial.Status) {
      if (this.model.detail.Status === this.serverConstants.squareStatusConstants.closed ||
        this.model.detail.Status === this.serverConstants.squareStatusConstants.archived) {
        shouldConfirmChangesList.push(SquareChangeType.CloseSquare);
      }
      if (this.model.detail.Status === this.serverConstants.squareStatusConstants.active) {
        shouldConfirmChangesList.push(SquareChangeType.OpenSquare);
      }
    }

    if (_.findIndex(savingUpModel.rewards, (reward) => reward.IsNew) !== -1 && savingUpModel.isSavingUpRewardsEnabled) {
      shouldConfirmChangesList.push(SquareChangeType.ChangeCountryRequiresCreditValue);
    }

    return shouldConfirmChangesList;
  }


  private updateSquareInternal(savingData, email) {
    type UpdateSquareResponse = ReturnType<typeof this.squareservice.updateSquare>;
    type UpdateJobIdReminderEmailResponse = ReturnType<typeof this.programservice.updateJobIdReminderEmail>;

    const tasks: Array<UpdateSquareResponse | UpdateJobIdReminderEmailResponse> = [this.squareservice.updateSquare(savingData)];
    if (this.isInSitesUser) {
      tasks.push(this.programservice.updateJobIdReminderEmail(this.$stateParams.programGuid, email));
    }

    this.$q.all(tasks).then(async (results) => {
      const updateSquareResponse = results[0] as Awaited<UpdateSquareResponse>;
      if (!updateSquareResponse) {
        return;
      }
      // Only Human8 Users can access and change the job id reminder emails so we don't need to handle the results if we're not
      // This if is just a patch job to make sure the save works for moderators as JobIds aren't changed and nothing is returned
      if (this.isInSitesUser) {
        const updateJobIdReminderEmailResponse = results[1] as Awaited<UpdateJobIdReminderEmailResponse>;
        if (!updateJobIdReminderEmailResponse) { // If we're an Human8 user, then we do expect the second call to be done and the results to be present
          return;
        }

        this.model.detail.JobIds = updateSquareResponse.JobIds.map((j) => ({
          Guid: j.Guid,
          JobId: j.JobId,
          SquareGuid: j.SquareGuid,
          StartDate: DateTime.fromISO(j.StartDate),
          Commitment: j.Commitment,
          Status: j.Status,
        }));
        this.initialJobIdReminderEmail = updateJobIdReminderEmailResponse.JobIdReminderEmail;
        this.jobIdReminderEmail = updateJobIdReminderEmailResponse.JobIdReminderEmail;
      }

      this.handleSquareUrlChanged();

      // Update to the new status if changed
      this.model.detail.Status = updateSquareResponse.Status;
      this.model.detail.StatusString = updateSquareResponse.StatusString;
      this.initial = angular.copy(this.model.detail);
      await this.setSelectedSquareFactory();
      if (this.squareInfo) {
        this.squareInfo.$setPristine();
      }
      this.formDataSaved = true;
      if (savingData.clonedSquareGuid) {
        if (updateSquareResponse.NotClonedSections) {
          this.logger.error(`Square partially cloned. The following section(s) where not cloned: ${updateSquareResponse.NotClonedSections}`);
        } else {
          this.logger.success('Square successfully cloned');
        }
        return;
      }
      this.model.detail.HasSsoData = updateSquareResponse.HasSsoData;
      this.configuredSavingUpRewardCurrencies = _.map(savingData.SavingUpRewards, (reward) => {
        const currency = _.find(this.currencies, (currencyObject) => currencyObject.Guid === reward.CurrencyGuid);
        return currency ? currency.Name : 'EUR';
      });
      this.initialCountries = angular.copy(this.model.Countries);
      this.logger.success('Square info has been saved');

      // Set ClonedSquareGuid to null so that a next update doesn't clone again
      this.model.detail.ClonedSquareGuid = null;

      if(this.isOpenRegistrationEnabled && (this.hasOpenRegistrationToggleChanged || this.hasOpenRegistrationScreenerChanged)) {
        const goToActivity = await this.squareOpenRegistrationService.showInfoDialog();
        this.hasOpenRegistrationToggleChanged = false;
        this.hasOpenRegistrationScreenerChanged = false;
        if (goToActivity) {
          this.$state.go('root.square.activityWizardType', {
            clientGuid: this.$stateParams.clientGuid,
            programGuid: this.$stateParams.programGuid,
            squareGuid: this.$stateParams.squareGuid,
            activityGuid: savingData.OpenRegistrationScreenerSurvey,
            step: 'Target',
          });
        }
      }
    }, (error) => {
      if (error.status === 400) {
        const _this = this;
        const data = error.data;
        let message = data.Message ? data.Message : '';
        // Group by property name in case there is more than 1 error for that property
        // Ideally we should already group them in the backend
        const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
        _.forEach(grouped, (item, key) => {
          message = '';
          if (item[0]) {
            if (item[0].ErrorMessage === _this.validationConstants.checkMarketMasterKeyRequired) {
              key = 'CheckMarketMasterKey';
            }
            if (item[0].ErrorMessage === _this.validationConstants.checkMarketKeyRequired) {
              key = 'CheckMarketKey';
            }
            message += item[0].ErrorMessage;
          } else {
            _.forEach(item, (errorMessage: any) => {
              message += `${errorMessage.ErrorMessage} `;
            });
          }
          if (_this.squareInfo && _this.squareInfo[key]) {
            _this.squareInfo[key].$setValidity('serverErrors', false);
            _this.squareInfo[key].errorMessage = message;
          }
        });
        _this.logger.error(`Square could not be saved due to following error: ${message}`);
      }
    }).finally(() => {
      this.isSaving = false;
      if (this.model.detail.Status !== this.serverConstants.squareStatusConstants.draft
        && DateTime.now() > DateTime.fromISO(this.model.detail.StartDate)) {
        this.hasStarted = true;
      }
    });
  }

  async getSavingUpModelWithNewCurrencies(): Promise<ISavingUpModel> {
    const featureSettings = await this.featureService.getFeatureSettings(this.serverConstants.featureConstants.savingUpRewards);
    const minUnitsRedeemSetting = featureSettings.settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsMinUnitsRedeem];
    const minUnitsRedeem = minUnitsRedeemSetting ? parseInt(minUnitsRedeemSetting, 10) : 1;
    const initialCountries: Set<string> = new Set(this.initialCountries.map((country) => country.Iso));
    const currentCountries: Set<any> = new Set(this.model.Countries.map((country) => country));
    const newCountries = [...currentCountries].filter((country) => !initialCountries.has(country.Iso));

    const rewards = [...currentCountries].map((country) => {
      const currency = this.currencies.find((currencyObject) => currencyObject.Name === country.CurrencyIso);
      return {
        CurrencyId: currency.Id,
        CurrencyGuid: currency.Guid,
        CurrencyName: currency.Name,
        Country: country.EnglishName,
        CountryIso: country.Iso,
        IsNew: newCountries.indexOf(country) >= 0,
        Units: minUnitsRedeem,
        Value: parseFloat(((country.Value * minUnitsRedeem)).toFixed(2)),
      };
    });

    const savingUpModel: ISavingUpModel = {
      rewards,
      isSavingUpRewardsEnabled: this.savingUpRewardsEnabled,
    };
    return savingUpModel;
  }

  newCountryWithNewCurrencyAdded() {
    return true;
  }

  async setSelectedSquareFactory() {
    const rightToLeft = await this.squareservice.getSquareLanguageDirection();
    const token = this.authService.getToken();
    this.selectedSquareFactory.setSquareInfo({
      Guid: this.model.detail.Guid,
      Name: this.model.detail.Name,
      SquareUrl: this.model.detail.Url,
      SsoEnabled: this.model.detail.SsoEnabled,
      Language: this.model.detail.Language.Code,
      StartDate: this.model.detail.StartDate,
      CreateDate: this.model.detail.DateCreated,
      RightToLeft: rightToLeft,
      Status: this.model.detail.Status,
      Token: token,
      Id: this.model.detail.Id,
      AllowPiiInExports: this.model.detail.AllowPiiInExports,
    });
  }

  getLanguageList() {
    return this.projectservice.getLanguageList().then((languages) => {
      this.languages = languages;
      return languages;
    });
  }

  async getIsSavingUpRewardsEnabled() {
    this.savingUpRewardsEnabled = await this.featureService.isFeatureEnabledForSquare(
      this.serverConstants.featureConstants.savingUpRewards);
  }

  async getScreenerSurveysForOpenRegistration() {
    return this.activityservice.listScreenerSurveysForOpenRegistration().then((screeners) => {
      this.screeners = screeners;
      this.selectedScreener = _.find(this.screeners, (screener) => screener.isOpenRegistrationScreener);
      this.isOpenRegistrationEnabled = this.selectedScreener !== undefined;
      return screeners;
    });
  }

  isScreenerClosed() {
    return this.selectedScreener && this.selectedScreener.actStatus === this.serverConstants.squareActivityStatusConstants.closed;
  }

  async getSquareCountries() {
    this.countries = await this.squareservice.getSquareCountries();
  }

  showCountries() {
    return this.countries.map((x) => x.EnglishName);
  }

  searchCountries = (query) => {
    let results = [];
    if (query) {
      query = query.toUpperCase();
      const notSelectedCountries = this.countries.filter((c) => !this.model.Countries.map((mc) => mc.Iso).includes(c.Iso));
      results = notSelectedCountries.filter((country) => country.EnglishName.toUpperCase().includes(query));
    }
    return _.take(results, 100);
  };

  getJobIds() {
    if (!this.isInSitesUser) {
      return [];
    }
    return this.programservice.getAvailableJobsForSquares(this.$stateParams.programGuid).then((response) => {
      const jobs = response.List;
      this.jobIds = jobs
        .map((job) => job.JobId);
      return this.$q.resolve(response);
    });
  }

  getSquareDetails() {
    return this.squareservice.getSquareDetails(this.$stateParams.squareGuid).then((response) => {
      const data = response.data;
      this.disabledSquareUrls = data.DisabledSquareUrls;
      data.Detail.Type = 'square';
      data.Detail.Language = data.Detail.Language ? _.find(this.languages, (item: any) => {
        if (data.Detail.Language === item.Code) {
          return item.Name;
        }
      }) : '';

      data.Detail.JobIds.forEach((jobId, index) => {
        if (!DateTime.isDateTime(jobId.StartDate)) {
          jobId.StartDate = DateTime.fromISO(jobId.StartDate);
        }
        if (jobId.StartDate != null) {
          data.Detail.JobIds[index].StartDate = this.dateFormatService.getDateTime(jobId.StartDate, this.currentTimeZone);
        }
      });

      if (!this.model.StartDateOffset && data.Detail.StartDateOffset) {
        this.model.StartDateOffset = data.Detail.StartDateOffset || this.currentTimeZone;
      }
      if (data.Detail.StartDate && !DateTime.isDateTime(data.Detail.StartDate)) {
        data.Detail.StartDate = DateTime.fromISO(data.Detail.StartDate);
      }
      data.Detail.StartDate = data.Detail.StartDate ?
        this.dateFormatService.getDateTime(data.Detail.StartDate as DateTime, data.Detail.StartDateOffset) :
        this.defaultDate;
      data.Detail.Url = data.Detail.Url ? data.Detail.Url : this.getDefaultUrl(data.Detail);
      data.Detail.ClonedSquareGuid = this.$stateParams.clonedSquareGuid;
      data.Detail.DigestMailTime = this.dateFormatService.getTimeLoadFormat(data.Detail.DigestMailTime, data.Detail.DigestEmailOffset);
      this.model.Countries = data.Countries ? data.Countries : [];
      this.initial = data.Detail;
      this.hasStarted = data.Detail.Status !== this.serverConstants.squareStatusConstants.draft && data.Detail.StartDate < DateTime.now();
      this.minDate = this.hasStarted ? data.Detail.StartDate : DateTime.now();
      this.model.detail = angular.copy(this.initial);
      this.configuredSavingUpRewardCurrencies = data.Detail.ConfiguredSavingUpRewardCurrencies;
      this.initialCountries = angular.copy(this.model.Countries);
      this.model.detail.StartTime = this.model.detail.StartDate;
      this.showVerifyItsYouSettings = this.model.detail.AdLogin;
      this.squareStatus = this.model.detail.Status;
      return this.$q.resolve(response);
    });
  }

  async getSquareFeatureSettings(): Promise<void> {
    const checkMarketFeatureSettings = await this.featureService.getFeatureSettings(this.serverConstants.featureConstants.checkMarket);
    this.checkMarketMasterSwitchEnabled = checkMarketFeatureSettings.isMasterSwitchEnabled;
    this.checkMarketEnabled = checkMarketFeatureSettings.isEnabled;
    this.checkMarketMasterKey = checkMarketFeatureSettings.settings[this.serverConstants.featureSettingKeysConstants.checkMarketMasterKey];
    this.checkMarketKey = checkMarketFeatureSettings.settings[this.serverConstants.featureSettingKeysConstants.checkMarketKey];
  }

  getDefaultUrl(square) {
    const name = square.Name.toLowerCase();
    const url = name.replace(/[^a-z0-9]+/g, '');
    return url;
  }

  querySearchLanguages(query) {
    return query ? this.createFilterForLanguages(query) : this.languages;
  }

  createFilterForLanguages(query) {
    return _.filter(this.languages, (item: any) => (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0));
  }

  querySearchScreeners(query) {
    return query ? this.createFilterForScreeners(query) : this.screeners;
  }

  createFilterForScreeners(query) {
    return _.filter(this.screeners, (item) => (item.activityName.toLowerCase().indexOf(query.toLowerCase()) === 0));
  }

  rollBackForm() {
    this.model.detail = angular.copy(this.initial);
    this.squareInfo.$setUntouched();
    this.squareInfo.$setPristine();
    this.formDataSaved = false;
    this.jobIdReminderEmail = this.initialJobIdReminderEmail;
    this.creditsReminderEmail = this.initialCreditsReminderEmail;
    this.squareJobStatusFilter = [this.serverConstants.jobIdStatusConstants.active, this.serverConstants.jobIdStatusConstants.notStarted];
  }

  validateNavigation(toParams, fromParams) {
    const result = {
      canNavigate: true,
      isDirty: false,
      hideSaveDraftBtn: !this.canSaveAndLeave(),
      navigationErrorMessage: null,
    };

    if (!this.isFormValid && this.squareInfo.$valid && !this.formDataSaved) {
      if (toParams !== undefined && fromParams !== undefined && toParams.squareGuid === fromParams.squareGuid) {
        this.navigationErrorMessage = 'Please save the changes';
        result.canNavigate = false;
      }
    }
    result.isDirty = this.squareInfo.$dirty;
    result.navigationErrorMessage = this.navigationErrorMessage;
    return result;
  }

  doesElementContainTextOrImage(element) {
    const text = element.textContent;
    const containsText = text && text.trim();
    const firstImage = element.querySelector('img');
    const containsImage = !!firstImage;
    return containsImage || containsText;
  }

  handleSquareUrlChanged() {
    if (this.model.detail.Url === this.initial.Url) {
      return;
    }

    // Remove new url from disabledSquareUrls
    const index = this.disabledSquareUrls.indexOf(this.model.detail.Url);
    if (index > -1) {
      this.disabledSquareUrls.splice(index, 1);
    }
  }

  fetchSsoIv() {
    this.isfetchingSsoCodes = true;
    this.squareservice.GetSsoIv()
      .then((iv) => {
        this.ssoIv = iv;
      })
      .finally(() => {
        this.isfetchingSsoCodes = false;
      });
  }

  fetchSsoKey() {
    this.isfetchingSsoCodes = true;
    this.squareservice.GetSsoKey()
      .then((key) => {
        this.ssoKey = key;
      })
      .finally(() => {
        this.isfetchingSsoCodes = false;
      });
  }

  openRegistrationToggleChanged(value) {
    this.hasOpenRegistrationToggleChanged = value;
  }

  openRegistrationScreenerChanged() {
    this.hasOpenRegistrationScreenerChanged = true;
  }

  private canSaveAndLeave() {
    return this.model.detail.Status === this.serverConstants.squareStatusConstants.draft || this.squareInfo.$valid;
  }

  onStartDateTimeChanged() {
    if (this.model.detail.StartTime) {
      this.model.detail.StartDate = this.model.detail.StartDate.set({ hour: this.model.detail.StartTime.hour, minute: this.model.detail.StartTime.minute });
    }
    this.squareInfo.dateField.$setValidity('serverErrors', true);
  }

  // Anonymous participation and Public Profile are mutually exclusive
  onAnonymousChanged(value) {
    if (value === true) {
      this.model.detail.IsPublicProfileEnabled = false;
    }
  }

  // Anonymous participation and Public Profile are mutually exclusive
  onIsPublicProfileEnabledChanged(value) {
    if (value === true) {
      this.model.detail.Anonymous = false;
    }
  }

  onPasswordLengthChanged(value) {
    if (value >= this.serverConstants.validationConstants.passwordMinLength) {
      this.model.detail.Length = value;
    }
  }

  getNameOfStatus(status: number): string {
    switch (status) {
      case 0: return 'Legacy';
      case 1: return 'Not started';
      case 2: return 'Active';
      case 3: return 'Expiring soon';
      case 4: return 'Expiring soon';
      case 5: return 'Expired';
      case 6: return 'Paused';
      default: return 'New';
    }
  }

  getExpirationDate(jobid: IJobId): DateTime {
    if (jobid.StartDate) {
      return jobid.StartDate.plus({ weeks: jobid.Commitment });
    }
    return null;
  }

  addJob(jobid: string): void {
    const jobId: IJobId = {
      Commitment: 1,
      StartDate: DateTime.utc(),
      Guid: '',
      JobId: jobid,
      Status: undefined,
      SquareGuid: this.$stateParams.squareGuid,
    };
    this.model.detail.JobIds.push(jobId);
  }

  canAddMoreJobs() {
    const selectedJobIds = this.model.detail.JobIds.map((j) => j.JobId);
    const difference = this.jobIds.filter((j) => selectedJobIds.indexOf(j) === -1);
    return difference.length > 0;
  }

  deleteJobId(index: number): void {
    this.model.detail.JobIds.splice(index, 1);
    this.squareInfo.$setDirty();
  }

  pauseJobId(index: number): void {
    this.model.detail.JobIds[index].Status = this.serverConstants.jobIdStatusConstants.paused;
    this.squareInfo.$setDirty();
  }

  reactivateJobId(index: number): void {
    this.model.detail.JobIds[index].Status = this.calculateActiveJobIdStatus(this.model.detail.JobIds[index]);
    this.squareInfo.$setDirty();
  }

  calculateActiveJobIdStatus(jobId: IJobId): number {
    const dateElapsed75 = jobId.StartDate.plus({ days: jobId.Commitment * 7 * 0.75 });
    const dateElapsed90 = jobId.StartDate.plus({ days: jobId.Commitment * 7 * 0.9 });
    const endDate = jobId.StartDate.plus({ days: jobId.Commitment * 7 });
    if (dateElapsed75.diffNow('days').toObject().days > 0) {
      return this.serverConstants.jobIdStatusConstants.active;
    }
    if (dateElapsed90.diffNow('days').toObject().days > 0) {
      return this.serverConstants.jobIdStatusConstants.elapsed75Perc;
    }
    if (endDate.diffNow('days').toObject().days > 0) {
      return this.serverConstants.jobIdStatusConstants.elapsed90Perc;
    }
  }

  getRemainingJobIds(): void {
    const existingJobIds = this.model.detail.JobIds.map((b) => b.JobId).slice(0, -1);
    this.existingJobIds = _.uniq(this.jobIds.filter((e) => !existingJobIds.includes(e)));
  }

  hasCorrectStatus(jobId: IJobId) {
    const filterStatus = jobId.Status === this.serverConstants.jobIdStatusConstants.elapsed90Perc ?
      this.serverConstants.jobIdStatusConstants.elapsed75Perc : jobId.Status;
    return this.squareJobStatusFilter.includes(filterStatus) || jobId.Status == null;
  }

  hasActiveJobIdStatus(status: number): boolean {
    return status === this.serverConstants.jobIdStatusConstants.active ||
      status === this.serverConstants.jobIdStatusConstants.elapsed75Perc ||
      status === this.serverConstants.jobIdStatusConstants.elapsed90Perc;
  }

  filterStatuses() {
    return (item) => this.squareJobStatusFilter.some((s) => s === item.Status) || item.Status == null;
  }

  delete(index: number) {
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Warning')
        .text('Make sure there is always a Job ID linked to the square platform.\
         Job ID needs to be in line with the project SAP configuration for platform fee.')
        .ok('I understand')
        .cancel('Cancel'),
    ).then(() => {
      this.deleteJobId(index);
    });
  }

  getCorrectStatus(status: number) {
    return Utils.getNameOfStatus(status);
  }

  getCorrectDateFormat(date: any): DateTime {
    return this.dateFormatService.convertDate(date);
  }
}
