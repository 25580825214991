'use strict';

import { DateTime } from 'luxon';
import { IForumConversation } from './activityData.service';
import { IProbeQuestionConversationElementItem } from './../../activity/activityData/conversationElementModel';
import { ActivityProbeQualBuildModel } from './../../activity/activityQualResearchConfig/activityQualSteps/activityProbeQualBuild/activityProbeQualBuildModel';
import { ActivityQualBuilderModel } from './../../activity/activityQualResearchConfig/activityQualSteps/activityQualBuilder/activityQualBuilderModel';
import { ActivityDiscussionNewBuildModel }
  from './../../activity/activityQualResearchConfig/activityQualSteps/activityDiscussionNewBuild/activityDiscussionNewBuildModel';
import { ServerConstants } from './../serverconstants';
import { ActivityScoutBuildModel } from './../../activity/activityQualResearchConfig/activityQualSteps/activityScoutBuild/activityScoutBuildModel';
import { IUploadConversationStimulus, IUploadConversationAttachment, IProbeQuestionEditorModel, IProbeQuestionAnswerModel } from 'isc-ui';
import * as _ from 'lodash';
import { ActivityBuildModelBase } from './../../activity/activityQualResearchConfig/activityQualSteps/common/activityBuildBase.model';
import { IConversationStimuli, IConversationAttachment, SquareActivityModel, ICommunicationStimuli }
  from '../../activity/activityQualResearchConfig/squareActivityModel';
import { ICommunicationChannelWithStimulus, IDiscussionActivityResponse, IStimulusViewModel } from '../contracts/discussion.contract';
import { ActivityObservationBuildModel } from './../../activity/activityQualResearchConfig/activityQualSteps/activityObservationBuild/activityObservationBuildModel';
import { CurrentUserService } from '../dataservices/currentUser.service';

export class MappingService {

  static $inject = ['serverConstants', 'currentUserService'];

  /** In the future it would be so nice to use something like
   * automapper, but for now, since we have only a few mappings,
   * we will do them the old way */
  constructor(
    private serverConstants: ServerConstants,
    private currentUserService: CurrentUserService,
  ) { }

  mapConversationStimuliForConversationEditor(input: IConversationStimuli[]): IUploadConversationStimulus[] {
    let result: IUploadConversationStimulus[] = [];
    if (input && input.length) {
      result = input.map((stimulus: IConversationStimuli) => {
        if (stimulus) {
          let stimulusValue: string = stimulus.Value;
          if (stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video) {
            stimulusValue = stimulus.Url || stimulus.Value;
          }
          let idValue: string = null;
          if (stimulus.Id !== null) {
            idValue = stimulus.Id.toString();
          }
          return {
            file: stimulus.File,
            type: stimulus.Type, // Always a number
            thumbnailUrl: stimulus.ThumbnailUrl,
            guid: stimulus.Guid,
            value: stimulusValue,
            id: idValue,
            url: stimulus.Url,
            isPreview: true,
            status: this.serverConstants.conversationStimuliStatusConstants.saved,
            IsBroken: stimulus.IsBroken,
            IsBrokenAndBelongsToCurrentUser: stimulus.IsBrokenAndBelongsToCurrentUser,
          } as IUploadConversationStimulus;
        }
      });
    }
    return result;
  }

  mapConversationAttachmentsForConversationEditor(input: IConversationAttachment[]): IUploadConversationAttachment[] {
    let result: IUploadConversationAttachment[] = [];
    if (input && input.length) {
      result = input.map((attachment: IConversationAttachment) => {
        if (attachment) {
          let idValue: string = null;
          if (attachment.Id !== null) {
            idValue = attachment.Id.toString();
          }
          return {
            file: attachment.File,
            type: this.serverConstants.stimulusTypeConstants.attachment, // Always a number
            thumbnailUrl: '',
            guid: attachment.Guid,
            value: attachment.Url,
            url: attachment.Url,
            id: idValue,
            name: attachment.Name,
            isPreview: true,
            status: this.serverConstants.conversationStimuliStatusConstants.saved,
          } as IUploadConversationAttachment;
        }
      });
    }
    return result;
  }

  mapSquareActivityModelForScoutActivity(data: SquareActivityModel, defaultConversationFocusType: number): ActivityScoutBuildModel {
    const model = new ActivityScoutBuildModel();
    if (data && data.ActivityQualDetail) {
      const qualData = data.ActivityQualDetail;
      model.openPostUsername = qualData.OpenPostUsername;
      model.description = qualData.Description;
      model.title = qualData.Title;
      model.caption = qualData.Caption;
      model.isOneByOne = qualData.IsOneByOne;
      model.conversationGuid = qualData.ConversationGuid;
      model.thankYouMessage = qualData.ThankYouMessage;
      model.conversationFocusType = qualData.ConversationFocusType || defaultConversationFocusType;
      model.probeQuestions = data.ProbeQuestions?.map((q, index) => ({
        guid: q.Guid,
        question: q.Message,
        questionType: q.QuestionType,
        isEdit: false,
        answerRequired: q.AnswerRequired,
        sortOrder: index,
      })) || [];
      model.stimuli = this.mapConversationStimuliForConversationEditor(data.ConversationStimuli);
      model.attachments = this.mapConversationAttachmentsForConversationEditor(data.ConversationAttachment);
      model.newStimulusFiles = [];
      model.newAttachmentFiles = [];
      if (data && data.Detail) {
        model.squareJobId = data.Detail?.SquareJobId;
      }
    }
    return model;
  }

  mapSquareActivityModelForProbeQual(data: SquareActivityModel): ActivityProbeQualBuildModel {
    const model = new ActivityProbeQualBuildModel();
    if (data && data.ActivityQualDetail) {
      const qualData = data.ActivityQualDetail;
      model.openPostUsername = qualData.OpenPostUsername;
      model.isOneByOne = qualData.IsOneByOne;
      model.description = qualData.Description;
      model.title = qualData.Title;
      model.caption = qualData.Caption;
      model.conversationGuid = qualData.ConversationGuid;
      model.thankYouMessage = qualData.ThankYouMessage;
      model.conversationFocusType = qualData.ConversationFocusType;
      model.contributionType = qualData.ContributionType;
      model.probeQuestions = data.ProbeQuestions?.map((q, index) => ({
        guid: q.Guid,
        question: q.Message,
        questionType: q.QuestionType,
        isEdit: false,
        answerRequired: q.AnswerRequired,
        sortOrder: index,
      })) || [];
      model.stimuli = this.mapConversationStimuliForConversationEditor(data.ConversationStimuli);
      model.attachments = this.mapConversationAttachmentsForConversationEditor(data.ConversationAttachment);
      model.newStimulusFiles = [];
      model.newAttachmentFiles = [];
      if (data && data.Detail) {
        model.squareJobId = data.Detail?.SquareJobId;
      }
    }
    return model;
  }

  mapSquareActivityModelForQualActivity(data: SquareActivityModel): ActivityQualBuilderModel {
    const model = new ActivityQualBuilderModel();
    if (data && data.ActivityQualDetail) {
      const qualData = data.ActivityQualDetail;
      model.openPostUsername = qualData.OpenPostUsername;
      model.callToActionText = qualData.CallToActionText;
      model.caption = qualData.Caption;
      model.cardTeaser = qualData.CardTeaser;
      model.description = qualData.Description;
      model.prePopulateResponse = qualData.PrePopulateDescription !== null;
      model.prePopulateDescription = qualData.PrePopulateDescription;
      model.title = qualData.Title;
      model.conversationGuid = qualData.ConversationGuid;
      model.communicationGuid = qualData.CommunicationGuid;
      model.stimuli = this.mapConversationStimuliForConversationEditor(data.ConversationStimuli);
      model.attachments = this.mapConversationAttachmentsForConversationEditor(data.ConversationAttachment);
      model.newStimulusFiles = [];
      model.newAttachmentFiles = [];
      model.conversationFocusType = qualData.ConversationFocusType;
      if (data.CommunicationStimuli != null) {
        model.communicationStimuli.push({
          source: 'db',
          action: 'ignore',
          imgUrl: data.CommunicationStimuli.ThumbnailUrl,
          type: data.CommunicationStimuli.FileType,
          Guid: data.CommunicationStimuli.Guid,
        });
      }
      model.startDateTime = DateTime.fromISO(data.Detail.StartDate);
      if (data && data.Detail) {
        model.squareJobId = data.Detail?.SquareJobId;
      }
    }
    return model;
  }

  mapProbeQuestionsForProbeQuestionEditor(probeQuestions: IProbeQuestionConversationElementItem[]): IProbeQuestionEditorModel[] {
    const model: IProbeQuestionEditorModel[] = [];
    _.forEach(probeQuestions, (probeQuestion) => {
      const answer: IProbeQuestionAnswerModel = {
        squareParticipantGuid: probeQuestion.SquareParticipantGuid,
        squareParticipantId: probeQuestion.SquareParticipantId,
        probeQuestionGuid: probeQuestion.Guid,
        guid: undefined,
        stimuli: [],
        attachments: [],
        newStimulusFiles: [],
        newAttachmentFiles: [],
        isValidAnswer: false,
        isInDraft: false,
      };
      if (probeQuestion.Answer) {
        answer.stimuli = this.mapConversationStimuliForConversationEditor(probeQuestion.Answer.Stimuli);
        answer.attachments = this.mapConversationAttachmentsForConversationEditor(probeQuestion.Answer.Attachments);
        answer.message = probeQuestion.Answer.Message;
        answer.caption = probeQuestion.Answer.Caption;
        answer.guid = probeQuestion.Answer.Guid;
        answer.isValidAnswer = probeQuestion.Answer.IsValidAnswer;
        answer.isInDraft = probeQuestion.Answer.IsInDraft;
        answer.squareParticipantId = probeQuestion.Answer.SquareParticipantId;
        answer.squareParticipantGuid = probeQuestion.Answer.SquareParticipantGuid;
      }
      const probeQuestionEditorModel: IProbeQuestionEditorModel = {
        isSlideIn: probeQuestion.IsSlideIn,
        level: probeQuestion.Level,
        message: probeQuestion.Message,
        questionType: probeQuestion.QuestionType,
        guid: probeQuestion.Guid,
        hasBeenAnswered: probeQuestion.HasBeenAnswered,
        answerRequired: probeQuestion.AnswerRequired,
        answer,
      };
      model.push(probeQuestionEditorModel);
    });
    return model;
  }

  mapActivityBuildModelToForumConversation(model: ActivityBuildModelBase): IForumConversation {
    const conversation: IForumConversation = {
      Guid: model.conversationGuid,
      Title: model.title,
      Message: model.description,
      Stimuli: model.stimuli,
      Attachments: model.attachments,
      Caption: model.caption,
      ConversationFocusType: model.conversationFocusType,
      ContributionType: model.contributionType,
      ProbeQuestions: model.probeQuestions,
      ThankYouMessage: model.thankYouMessage,
    };
    return conversation;
  }

  mapSquareActivityModelForDiscussionNewActivity(data: SquareActivityModel): ActivityDiscussionNewBuildModel {
    const model = new ActivityDiscussionNewBuildModel();
    if (data && data.ActivityQualDetail) {
      const qualData = data.ActivityQualDetail;
      model.openPostUsername = qualData.OpenPostUsername || this.currentUserService.userName;
      model.description = qualData.Description;
      model.title = qualData.Title;
      model.caption = qualData.Caption;
      model.isOneByOne = qualData.IsOneByOne;
      model.conversationGuid = qualData.ConversationGuid;
      model.thankYouMessage = qualData.ThankYouMessage;

      if (data.discussionModel) {
        model.conversationFocusType = this.convertAppearanceToFocusType(data.discussionModel.appearance);
      }
      model.probeQuestions = data.ProbeQuestions?.map((q, index) => ({
        guid: q.Guid,
        question: q.Message,
        questionType: q.QuestionType,
        isEdit: false,
        answerRequired: q.AnswerRequired,
        sortOrder: index,
      })) || [];
      model.stimuli = this.mapConversationStimuliForConversationEditor(data.ConversationStimuli);
      model.attachments = this.mapConversationAttachmentsForConversationEditor(data.ConversationAttachment);
      model.newStimulusFiles = [];
      model.newAttachmentFiles = [];
      if (data && data.Detail) {
        model.squareJobId = data.Detail?.SquareJobId;
      }
    }
    return model;
  }

  mapSquareActivityModelForObservationActivity(data: SquareActivityModel): ActivityObservationBuildModel {
    const model = new ActivityObservationBuildModel();
    if (data && data.ActivityQualDetail) {
      const qualData = data.ActivityQualDetail;
      model.openPostUsername = qualData.OpenPostUsername;
      model.description = qualData.Description;
      model.title = qualData.Title;
      model.caption = qualData.Caption;
      model.conversationGuid = qualData.ConversationGuid;
      model.thankYouMessage = qualData.ThankYouMessage;
      if (data.discussionModel) {
        model.conversationFocusType = this.convertAppearanceToFocusType(data.discussionModel.appearance);
      }
      model.probeQuestions = data.ProbeQuestions?.map((q, index) => ({
        guid: q.Guid,
        question: q.Message,
        questionType: q.QuestionType,
        isEdit: false,
        answerRequired: q.AnswerRequired,
        sortOrder: index,
      })) || [];
      model.stimuli = this.mapConversationStimuliForConversationEditor(data.ConversationStimuli);
      model.attachments = this.mapConversationAttachmentsForConversationEditor(data.ConversationAttachment);
      model.newStimulusFiles = [];
      model.newAttachmentFiles = [];
      if (data && data.Detail) {
        model.squareJobId = data.Detail?.SquareJobId;
      }
    }
    return model;
  }

  mapObservationModelToSquareActivityModel(observationModel: ActivityObservationBuildModel, squareActivityModel: SquareActivityModel) {
    if (observationModel.contributionType) {
      squareActivityModel.ActivityQualDetail.ContributionType = observationModel.contributionType;
      squareActivityModel.discussionModel.contributionType = observationModel.contributionType;
    }
    squareActivityModel.ActivityQualDetail.ConversationFocusType = observationModel.conversationFocusType;
    squareActivityModel.discussionModel.appearance = this.convertFocusTypeToAppearance(observationModel.conversationFocusType);
  }

  mapDiscussionStimuliForConversationEditor(input: IStimulusViewModel[], discussionGuid?: string): IConversationStimuli[] {
    const result: IConversationStimuli[] = [];
    if (input && input.length) {

      _.forEach(input, (stimulus) => {
        if (stimulus) {
          let stimulusValue = ''; // stimulus.Value;
          if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.youtube ||
            stimulus.type === this.serverConstants.conversationStimuliTypeConstants.vimeo) {
            stimulusValue = stimulus.uploadId;
          }

          result.push({
            // field not used for New Discussion - probably will be used at a later point
            // file: null,
            Type: stimulus.type,
            ThumbnailUrl: stimulus.thumbnailUrl?.toString(),
            Guid: stimulus.guid,
            Value: stimulusValue,
            Id: stimulus.guid,
            Url: stimulus.url?.toString(),
            ConversationGuid: discussionGuid,
            isPreview: true,
            status: this.serverConstants.conversationStimuliStatusConstants.saved,
            // field not used for New Discussion - probably will be used at a later point
            // IsBroken: stimulus.IsBroken,
            // field not used for New Discussion - probably will be used at a later point
            // IsBrokenAndBelongsToCurrentUser: stimulus.IsBrokenAndBelongsToCurrentUser,
          } as IConversationStimuli);

        }
      });
    }
    return result;
  }

  mapDiscussionAttachmentForConversationEditor(input: IStimulusViewModel[], discussionGuid?: string): IConversationAttachment[] {
    const result: IConversationAttachment[] = [];
    if (input && input.length) {
      _.forEach(input, (attachment) => {
        if (attachment) {
          // field not used for New Discussion - probbably will be used at a later point
          // let idValue: string = null;
          // if (stimulus.Id !== null) {
          //   idValue = stimulus.Id.toString();
          // }

          result.push({
            // field not used for New Discussion - probbably will be used at a later point
            // name: '',
            // field not used for New Discussion - probbably will be used at a later point
            // file: null,
            // field not used for New Discussion - probbably will be used at a later point
            // name: attachment.
            ConversationGuid: discussionGuid,
            // FileType: attachment.type,
            Guid: attachment.guid,
            Name: attachment.name,
            Id: attachment.guid,
            Url: attachment?.url?.toString(),
            // field not used for New Discussion - probbably will be used at a later point
            // status: this.serverConstants.conversationStimuliStatusConstants.saved,
            // field not used for New Discussion - probbably will be used at a later point
            // IsBroken: stimulus.IsBroken,
            // field not used for New Discussion - probbably will be used at a later point
            // IsBrokenAndBelongsToCurrentUser: stimulus.IsBrokenAndBelongsToCurrentUser,
          } as IConversationAttachment);

        }
      });
    }
    return result;
  }

  mapDiscussionActivityDetailsToSquareActivityModel(
    discussionModel: IDiscussionActivityResponse,
    activityModel: SquareActivityModel): SquareActivityModel {
    if (discussionModel && activityModel.ActivityQualDetail) {
      activityModel.discussionModel = discussionModel;
      if (!activityModel.ActivityQualDetail.OpenPostUsername) {
        // if activity is not published yet (draft) we will always take the original user
        activityModel.ActivityQualDetail.OpenPostUsername =
          activityModel.ActivityQualDetail?.IsPublished === true
            ? discussionModel.post?.onBehalfOf?.username
              ?? discussionModel.post?.author?.username
            : discussionModel.post?.author?.username;
      }
      activityModel.ActivityQualDetail.Description = discussionModel.post?.message.text;
      activityModel.ActivityQualDetail.Title = discussionModel.title;
      activityModel.ActivityQualDetail.Caption = discussionModel.caption;
      activityModel.ActivityQualDetail.IsOneByOne = discussionModel.isOneByOne;
      activityModel.ActivityQualDetail.ConversationGuid = discussionModel.discussionGuid;
      activityModel.ActivityQualDetail.Visibility = discussionModel.visibilityType;

      // field not used for New Discussion - probbably will be used at a later point
      // model.thankYouMessage = qualData.ThankYouMessage;
      const focusType: number = this.convertAppearanceToFocusType(discussionModel.appearance);
      activityModel.ActivityQualDetail.ConversationFocusType = focusType;

      activityModel.ProbeQuestions = discussionModel.post?.questions.map((q) => ({
        Guid: q.guid,
        Message: q.text,
        QuestionType: q.type,
        Type: q.type,
        IsEdit: false,
        AnswerRequired: !q.isOptional,
        ActivityGuid: activityModel.ActivityQualDetail.ActivityGuid,
        DateCreated: null,
        HasImage: null,
        HasVideo: null,
        Id: null,
        IsFavorite: null,
        IsOpeningPost: null,
        Set: null,
        SquareParticipantGuid: null,
        SquareParticipantId: null,
        Title: null,
        Topic: null,
        Username: null,
      })) ?? [];

      activityModel.ConversationStimuli =
        this.mapDiscussionStimuliForConversationEditor(discussionModel.post?.message.stimuli.filter((p) => p.type !== 4), discussionModel.discussionGuid);
      activityModel.ConversationAttachment =
        this.mapDiscussionAttachmentForConversationEditor(discussionModel.post?.message.stimuli.filter((p) => p.type === 4), discussionModel.discussionGuid);
      // field not used for New Discussion - probbably will be used at a later point
      // activityModel.newStimulusFiles = [];
      // field not used for New Discussion - probbably will be used at a later point
      // activityModel.newAttachmentFiles = [];
      // field not used for New Discussion - probbably will be used at a later point
      // if (data && data.Detail) {
      //   model.squareJobId = data.Detail?.SquareJobId;
      // }
    }
    return activityModel;
  }

  convertAppearanceToFocusType(appearance: number) {
    let focusType: number = null;
    switch (appearance) {
      case this.serverConstants.appearanceConstants.centered:
        focusType = this.serverConstants.conversationFocusTypeConstants.visual;
        break;
      case this.serverConstants.appearanceConstants.forum:
        focusType = this.serverConstants.conversationFocusTypeConstants.text;
        break;
      default:
        focusType = this.serverConstants.conversationFocusTypeConstants.text;
        break;
    }
    return focusType;
  }

  convertFocusTypeToAppearance(focusType: number) {
    let appearance: number = null;
    switch (focusType) {
      case this.serverConstants.conversationFocusTypeConstants.text:
        appearance = this.serverConstants.appearanceConstants.forum;
        break;
      case this.serverConstants.conversationFocusTypeConstants.visual:
        appearance = this.serverConstants.appearanceConstants.centered;
        break;
      default:
        appearance = this.serverConstants.appearanceConstants.forum;
        break;
    }

    return appearance;
  }

  mapCommunicationDetailsToSquareActivityModel(communicationModel: ICommunicationChannelWithStimulus,
    activityModel: SquareActivityModel): SquareActivityModel {
    if (communicationModel) {
      if (communicationModel.Channel && activityModel.ActivityQualDetail) {
        activityModel.ActivityQualDetail.CallToActionText = communicationModel.Channel.CallToActionText;
        activityModel.ActivityQualDetail.CardTeaser = communicationModel.Channel.Message;
        activityModel.ActivityQualDetail.CommunicationGuid = communicationModel.Channel.CommunicationGuid;
        activityModel.ActivityQualDetail.ShowOnHomepage = communicationModel.Channel.ShowOnHomepage;
        activityModel.ActivityQualDetail.Sticky = communicationModel.Channel.Sticky;
      }
      if (communicationModel.Stimulus) {
        activityModel.CommunicationStimuli = {
          ThumbnailUrl: communicationModel.Stimulus.ThumbnailUrl,
          FileType: communicationModel.Stimulus.FileType,
          Guid: communicationModel.Stimulus.Guid,
          Type: communicationModel.Stimulus.Type,
          Url: communicationModel.Stimulus.Url,
        } as ICommunicationStimuli;
      }
    }

    return activityModel;
  }
}
