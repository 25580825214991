'use strict';

import { SquareService } from 'src/app/core/dataservices/square.service';
import { ServerConstants } from '../../../core/serverconstants';
import { IAdLandingPageImageSettings, IImageSettingsModel } from '../design/images/imageSettingsModel';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { SquareLandingPageConfigResponse } from 'src/app/core/dataservices/square.service.contracts';

export class OpenRegistrationController {
  static $inject = ['$scope', '$mdDialog', '_', 'logger', 'serverConstants', 'authService', 'squareservice'];

  constructor(
    private $scope: ng.IScope,
    private $mdDialog: ng.material.IDialogService,
    private _: _.LoDashStatic,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private authService: AuthService,
    private squareService: SquareService,
  ) {

  }

  form: ng.IFormController;
  isSaving = false;
  config: SquareLandingPageConfigResponse;

  emptyModel: IImageSettingsModel = {
    Label: '',
    Tooltip: '',
    StoredImage: '',
    AlternateImage: '',
    MaxSize: '',
    Text: '',
    IsStored: false,
  };
  settings: IAdLandingPageImageSettings = {
    logoTopBarLanding: this.emptyModel,
    backgroundLanding: this.emptyModel,
  };
  imagesToDelete = [];
  images;
  imagesError;

  async $onInit() {
    const configResponse = await this.squareService.getSquareLandingPageConfig();
    this.config = configResponse.data || {
      HasBackground: false,
      HasTopLogo: false,
      Text: '',
      Title: '',
    };
    this.settings.logoTopBarLanding = {
      Tooltip: `This logo is used on the <strong><u>top left of the web</u></strong> version
        and is displayed in the <strong><u>mobile app header, against a white background</u></strong>`,
      Label: 'Logo top bar',
      StoredImage: this.serverConstants.imagesConstants.adLandingPageLogoTopBar,
      AlternateImage: 'images/top-logo.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>160 x 35px<b>',
      IsStored: this.config.HasTopLogo,
    };

    this.settings.backgroundLanding = {
      Label: 'Background image',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.adLandingPageBackground,
      AlternateImage: 'images/background-image.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>1920 x 1080px<b>',
      IsStored: this.config.HasBackground,
    };
    this.$scope.$broadcast('isc-images-saved');
  }

  async savePage() {
    this.isSaving = true;

    if (!await this.saveImages()) {
      this.isSaving = false;
      return;
    }

    // save configuration
    this.config.HasTopLogo = this.settings.logoTopBarLanding.IsStored === true;
    this.config.HasBackground = this.settings.backgroundLanding.IsStored === true;
    try {
      const success = await this.squareService.setSquareLandingPageConfig(this.config);
      if (success) {
        this.logger.success('Landing page configuration saved successfully');
      } else {
        this.logger.error('Error saving landing page configuration');
      }
    } catch {
      this.logger.error('Error saving landing page configuration');
    }

    this.isSaving = false;
  }

  async saveImages(): Promise<boolean> {
    const self = this;
    self._.forEach(self.images, (image, key) => {
      delete self.images[key];
      const oldKey = key;
      const newKey = self._.kebabCase(key);
      if (image === null) {
        this.imagesToDelete.push(newKey);
      } else {
        self.images[newKey] = image;
        self.settings[oldKey].IsStored = true;
      }
    });
    if (self._.values(self.images).some((x: any) => {
      if (x.size > 1048576) {
        self.imagesError.message = 'Some images are too large!';
        self._.forEach(self.images, (image, key) => {
          if (image === x) {
            delete self.images[key];
          }
        });
      }
      return x != null;
    })) {
      if (this._.values(this.imagesError).some((x) => x != null)) {
        this.$mdDialog.show(this.$mdDialog.iscAlert({
          title: 'Save page',
          text: 'Some images are invalid, operation cancelled.',
          ok: 'Ok',
        }));
        return false;
      }
      return await this.saveSquareImages();
    }
    if (this.imagesToDelete.length > 0) {
      try {
        await this.squareService.deleteDesignImages(this.imagesToDelete);
        this.imagesError = {};
        this.imagesToDelete = [];
        this.form.$setPristine();
      } catch {
        return false;
      }
    }
    return true;
  }

  async saveSquareImages(): Promise<boolean> {
    try {
      await this.squareService.saveSquareImages(this.images);
      return true;
    } catch {
      return false;
    }
  }

}
