'use strict';

import { ITagItem, IActivityFilterData, IHashtagItem } from '../contracts/activity.contract';
import { IPromise } from 'angular';
import { DownloadService } from '../services/download.service';
import { HttpService } from './http.service';
import { IProbeQuestion } from '../services/activityData.service';
import { IProbeQuestionAnswerModel, ISaveProbeQuestionAnswerAsDraftResponse, IUploadConversationStimulus, IUploadConversationAttachment } from 'isc-ui';
import { ServerConstants } from '../serverconstants';
import { MuxService } from './mux.service';
import { IConversationStimuli, IConversationAttachment } from '../../activity/activityQualResearchConfig/squareActivityModel';
import { FileStorageService } from './fileStorage.service';
import { SelectedClientFactory } from '../selectedclient.factory';

export class ForumService {
  static $inject = ['httpservice', 'downloadservice', '$q', 'serverConstants', 'muxService', 'fileStorageService', '__env', 'logger', 'selectedClientFactory'];

  constructor(
    private httpservice: HttpService,
    private downloadservice: DownloadService,
    private $q: ng.IQService,
    private serverConstants: ServerConstants,
    private muxService: MuxService,
    private fileStorageService: FileStorageService,
    private __env: Environment,
    private logger: Logger,
    private selectedClientFactory: SelectedClientFactory,
  ) {
  }

  listForumRooms(squareGuid) {
    return this.httpservice.post({
      url: '/ForumService/ListForumRooms',
      data: {
        squareGuid,
      },
    });
  }

  getForumRoom(pageGuid) {
    return this.httpservice.post({
      url: '/ForumService/GetForumRoomsAdmin',
      data: {
        Guid: pageGuid,
      },
    });
  }

  getConversationsInRoom(roomGuid, pageNumber, limit) {
    return this.httpservice.post({
      url: '/ForumService/GetConversationsInRoom',
      data: {
        roomGuid,
        pageNumber,
        limit,
      },
    });
  }

  getConversation(conversationGuid) {
    return this.httpservice.post({
      url: '/ForumService/GetForumConversation',
      data: {
        conversationGuid,
      },
    });
  }

  async getMentionUsersForConversationAdmin(activityGuid: string, searchQuery: string, parentReplyGuid: string) {
    const response = await this.httpservice.post({
      url: '/ForumService/GetMentionUsersForConversationAdmin',
      data: {
        activityGuid,
        searchQuery,
        parentReplyGuid,
      },
    });
    return response.data.map((mention) => ({ label: mention.Label }));
  }

  async getHashtagsForConversation(activityGuid: string, searchQuery: string) {
    const response = await this.httpservice.post({
      url: '/ForumService/GetHashtagsForConversation',
      data: {
        activityGuid,
        searchQuery,
      },
    });
    return response.data.map((hashtag) => ({ text: hashtag.Text }));
  }

  getConversationElements(conversationGuid, pageNumber, limit, searchTerm, sortOption) {
    return this.httpservice.post({
      url: '/ForumService/GetConversationElements',
      data: {
        conversationGuid,
        pageNumber,
        limit,
        searchTerm,
        sortOption,
      },
    });
  }

  getMemberDetails(userGuid) {
    return this.httpservice.post({
      url: '/ForumService/GetMemberDetails',
      data: {
        squareParticipantGuid: userGuid,
      },
    }).then((response) => response.data);
  }

  deleteConversation(conversationGuid) {
    return this.httpservice.post({
      url: '/ConversationService/DeleteConversation',
      data: { conversationGuid },
    });
  }

  searchRoom(roomGuid, searchTerm, currentPage, limit) {
    return this.httpservice.post({
      url: '/ForumService/SearchRoom',
      data: {
        roomGuid,
        searchTerm,
        currentPage,
        limit,
      },
    });
  }

  createConversationStimuliStreamingUrl(stimuliGuid) {
    return this.httpservice.post({
      url: '/ConversationService/CreateConversationStimuliStreamingUrl',
      data: {
        StimuliGuid: stimuliGuid,
      },
    }).then((response) => response.data.Url);
  }

  async downloadStimuli(stimuliGuid): Promise<void> {
    try {
      const response = await this.httpservice.post({
        url: '/ExportService/DownloadStimuli',
        responseType: 'arraybuffer',
        params: {
          stimuliGuid,
        },
      });

      this.downloadservice.downloadResponse(response);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this.$q.resolve();
    }
  }

  censorConversation(guid) {
    return this.httpservice.post({
      url: '/ConversationService/CensorConversation',
      data: { Guid: guid },
    });
  }

  getQualConversationElementOrDefault(replyGuid, limit) {
    return this.httpservice.post({
      url: '/ForumService/GetQualConversationElementOrDefault',
      data: {
        replyGuid,
        limit,
      },
    }).then((response) => response.data);
  }

  getQualConversationElementsForModeration(
    activityGuid: string,
    pageNumber: number,
    limit,
    filter: IActivityFilterData,
    sortOption,
    expandedPosts,
    expandLatestUpdatePost,
    newReplyGuid?,
    impersonatedUserGuid?,
    isGroupedByMember?) {
    return this.httpservice.post({
      url: '/ForumService/GetQualConversationElementsForModeration',
      data: {
        activityGuid,
        pageNumber,
        limit,
        ...filter,
        sortOption,
        expandedPosts,
        expandLatestUpdatePost,
        includingNewConversationGuid: newReplyGuid,
        impersonatedUserGuid,
        isGroupedByMember,
      },
    }).then((response) => response.data);
  }

  getFirstUnReadConversation(activityGuid, pageNumber, limit, filter: IActivityFilterData, sortOption, expandedPosts, expandLatestUpdatePost) {
    return this.httpservice.post({
      url: '/ForumService/GetFirstUnReadConversation',
      data: {
        activityGuid,
        pageNumber,
        limit,
        ...filter,
        sortOption,
        expandedPosts,
        expandLatestUpdatePost,
      },
    }).then((response) => response.data);
  }

  deleteConversationUnReadDiscussion(conversationGuids) {
    return this.httpservice.post({
      url: '/ConversationService/DeleteConversationUnReadDiscussion',
      data: {
        conversationGuids,
      },
    });
  }

  likeQualConversation(postGuid: string) {
    return this.httpservice.post({
      url: '/ConversationService/LikeQualConversation',
      data: {
        ConversationGuid: postGuid,
      },
    });
  }

  unlikeQualConversation(postGuid: string) {
    return this.httpservice.post({
      url: '/ConversationService/UnlikeQualConversation',
      data: {
        ConversationGuid: postGuid,
      },
    });
  }

  replyToQualConversation(request, files, attachments) {
    return this.httpservice.upload({
      url: '/ConversationService/ReplyToQualConversation',
      data: {
        request: angular.toJson(request),
        files,
        attachments,
      },
    }).then((response) => response.data);
  }

  setRating(conversationGuid, rating) {
    return this.httpservice.post({
      url: '/ConversationService/SetRating',
      data: {
        Guid: conversationGuid,
        Value: rating,
      },
    });
  }

  createQualConversationUpdatePost(request, stimuli: File[], attachments: File[]) {
    return this.httpservice.upload({
      url: '/ConversationService/CreateQualConversationUpdatePost',
      data: {
        request: angular.toJson(request),
        files: stimuli,
        attachments,
      },
    });
  }

  getConversationTagList(activityGuid): IPromise<ITagItem[]> {
    return this.httpservice.post({
      url: '/ForumService/GetConversationTagList',
      data: {
        ActivityGuid: activityGuid,
      },
    }).then((response) => response.data);
  }

  getConversationHashtagList(activityGuid): IPromise<IHashtagItem[]> {
    return this.httpservice.post({
      url: '/ForumService/GetConversationHashtagList',
      data: {
        ActivityGuid: activityGuid,
      },
    }).then((response) => response.data);
  }

  getProbequestionsForFiltering(activityGuid) {
    return this.httpservice.post({
      url: '/ForumService/GetProbequestionsForFiltering',
      data: {
        activityGuid,
      },
    }).then((response) => response.data);
  }

  async getQualSampleGuidFromReply(replyGuid) {
    return this.httpservice.post({
      url: '/ForumService/GetQualSampleGuidFromReply',
      data: {
        replyGuid,
      },
    }).then((response) => response.data);
  }

  getConversationLocation(replyGuid, limit) {
    return this.httpservice.post({
      url: '/ForumService/GetConversationLocation',
      data: {
        ReplyGuid: replyGuid,
        Limit: limit,
      },
    }).then((response) => response.data);
  }

  getVimeoThumbnailImagePath(videoId) {
    return this.httpservice.get({
      url: '/ForumService/GetVimeoThumbnailImagePath',
      params: {
        videoId,
      },
    });
  }

  replyToForumConversation(request, files: File[], attachments: File[]) {
    return this.httpservice.upload({
      url: '/ConversationService/ReplyToConversation',
      data: {
        request: angular.toJson(request),
        files,
        attachments,
      },
    }).then((response) => response.data);
  }

  conversationHighlight(conversationGuid, message, snippet, highlightedElementsCommonIdentifier, highlight) {
    return this.httpservice.post({
      url: '/ConversationService/ConversationHighlight',
      data: {
        Guid: conversationGuid,
        message,
        snippet,
        highlightedElementsCommonId: highlightedElementsCommonIdentifier,
        Highlight: highlight,
      },
    }).then((response) => response.data);
  }

  addUserToGroup(group) {
    return this.httpservice.post({
      url: '/ConversationService/AddUserToGroup',
      params: {
        groupName: group,
      },
    });
  }
  removeUserFromGroup(group) {
    return this.httpservice.post({
      url: '/ConversationService/RemoveUserFromGroup',
      params: {
        groupName: group,
      },
    });
  }

  public async saveQualReplyAsDraft(request: any, files: any[], attachments: any): Promise<ISaveQualReplyAsDraftResponse> {
    return await this.httpservice.upload({
      url: '/ConversationService/SaveQualReplyAsDraft',
      data: {
        request: angular.toJson(request),
        files,
        attachments,
      },
    }).then((response: { data: ISaveQualReplyAsDraftResponse }) => response.data);
  }

  public saveProbeQuestionAnswerAsDraft(
    probingAnswer: IProbeQuestionAnswerModel,
    files: File[],
    attachments: File[],
    activityType: number,
    conversationFocusType?: number) {
    return this.httpservice.upload({
      url: '/ConversationService/SaveProbequestionAnswerAsDraft',
      data: {
        request: angular.toJson({ probingAnswer, activityType, conversationFocusType }),
        files,
        attachments,
      },
    }).then((response: { data: ISaveProbeQuestionAnswerAsDraftResponse }) => response.data);
  }

  public async saveProbeQuestionAsDraft(questions: IProbeQuestion[], inDraftConversationGuid: string) {
    const questionsRequest = questions.map((q) => ({
      Question: q.question,
      QuestionType: q.questionType,
    }));

    return this.httpservice.post({
      url: '/ConversationService/SaveProbequestionsAsDraft',
      data: {
        Questions: questionsRequest,
        InDraftConversationGuid: inDraftConversationGuid,
      },
    });
  }

  public async removeInDraftReply(parentMessageGuid: string) {
    return this.httpservice.post({
      url: '/ConversationService/RemoveInDraftReply',
      data: {
        ParentMessageGuid: parentMessageGuid,
      },
    });
  }

  replyToProbingQuestions(
    probingAnswers: IProbeQuestionAnswerModel[],
    files: File[],
    attachments: File[],
    originalSquareParticipantGuid?: string,
    activityType?: number,
    conversationFocusType?: number,
    contributionType?: number,
    channelType?: number) {
    return this.httpservice.upload({
      url: '/ConversationService/ReplyToProbingQuestions',
      data: {
        request: angular.toJson({ probingAnswers, originalSquareParticipantGuid, activityType, conversationFocusType, contributionType, channelType }),
        files,
        attachments,
      },
    }).then((response) => response.data);
  }

  public async prepareAttachmentsAndNewAttachments(
    attachments: IUploadConversationAttachment[]): Promise<IAttachmentAndFilesResult> {
    const result: IAttachmentAndFilesResult = {
      files: [],
      attachments: [],
    };
    for (const attachment of attachments) {
      if (this.__env.environment !== 'LOCALHOST' && attachment.file !== undefined) {
        const attachmentUploadData = await this.fileStorageService.GetNewUploadLinkForAttachment(attachment.file.type);
        if (attachmentUploadData && attachmentUploadData.data) {
          this.fileStorageService.uploadAttachment(attachmentUploadData.data, attachment.file);
          attachment.mimeType = attachment.file.type;
          attachment.file = undefined;
          attachment.id = attachmentUploadData.data.AttachmentGuid;
          attachment.url = attachmentUploadData.data.AttachmentSasUrl.split('?')[0];
        } else {
          result.files.push(attachment.file);
        }
        result.attachments.push(attachment);
      } else {
        result.attachments.push(attachment);
        if (attachment.file !== undefined) {
          result.files.push(attachment.file);
        }
      }
    }

    return result;
  }

  public async prepareStimuliAndNewStimuli(
    stimuli: IUploadConversationStimulus[], isCommunication = false): Promise<IStimuliAndFilesResult> {
    const result: IStimuliAndFilesResult = {
      files: [],
      stimuli: [],
    };

    /*
      Ok, we enter here with the list of stimuli (both already saved and new ones)
        and the list of files for new stimuli (yes, from the first list)

      Now, if we have mux, this is what we`re gonna` do:
        - ignore the input files list, we`re gonna` compose it
        - for each stimulus in list
            - stimulus does not have a new file
                - it will be pushed to the result`s stimuli list (no changes)
            - stimulus has a new video file
                - we try to create mux link and task for background upload
                    - if we fail, we push the stimulus to the result`s stimuli list,
                        as well as the file, so that backend can do whatever the hell
                        it wants with the file, we couldn`t upload it to mux
                    - if we succeed, we get the mux upload ID from the new upload
                        and we push the stimulus with the mux ID to the result`s stimuli list,
                        and we set that stimulus file to undefined so that backend doesn`t
                        upload it, and we don`t push the file to the result`s files list
    */
    this.logger.info('Client upload is:', this.muxService.MuxServiceEnabled);
    for (const stimulus of stimuli) {
      const isfile = stimulus.file instanceof File;
      const isblob = stimulus.file instanceof Blob;
      if (stimulus.file && (isfile || isblob)) {
        let video = stimulus.type === 1;
        if (!video) {
          const typeindex = Object.keys(stimulus).indexOf('type');
          if (typeindex !== -1) {
            const typeString: string = Object.values(stimulus)[typeindex];
            if (typeString && typeString !== '') {
              video = typeString.indexOf('video') !== -1;
            }
          }
        }
        if (video === true) {
          // There is a file and its a video
          if (this.muxService.MuxServiceEnabled) {
            const muxData = await this.muxService.getNewUploadLinkForVideo();
            if (muxData && muxData.data) {
              // We got a new upload ID from backend ;)
              this.muxService.uploadVideo(muxData.data, stimulus.file);
              // We do not put files to result, instead, we put stimuli with ID
              // stimulus.file = undefined; //we do not hide until save
              stimulus.fileContentToBeRemoved = true;
              stimulus.id = muxData.data.Id;
            } else {
              result.files.push(stimulus.file);
            }
          } else {
            const videoData = await this.fileStorageService.GetNewUploadLinkForVideo(stimulus.file.type, isCommunication);
            if (videoData && videoData.data) {
              // Upload into azure media services using the Url from amsData.data
              this.fileStorageService.uploadVideo(videoData.data, stimulus.file);
              stimulus.fileContentToBeRemoved = true;
              stimulus.id = videoData.data.StimuliGuid;
              stimulus.url = videoData.data.Url.split('?')[0];
              stimulus.thumbnailUrl = this.selectedClientFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.azureMediaServices
                ? this.getThumbnailUrl(stimulus.url)
                : null;
            } else {
              result.files.push(stimulus.file);
            }
          }
          result.stimuli.push(stimulus);
        } else if (this.__env.environment !== 'LOCALHOST' && stimulus.type === 0) {
          // There is a file and is a image
          const photoUploadData = await this.fileStorageService.GetNewUploadLinkForPhoto(stimulus.file.type);
          if (photoUploadData && photoUploadData.data) {
            this.logger.info('Uploading photo', photoUploadData.data.StimuliGuid);
            this.fileStorageService.uploadImage(photoUploadData.data, stimulus.file);
            // stimulus.file = undefined; //we do not hide it till save
            stimulus.fileContentToBeRemoved = true;
            stimulus.url = photoUploadData.data.PhotoSasUrl.split('?')[0];
            stimulus.thumbnailUrl = this.getThumbnailUrl(photoUploadData.data.PhotoSasUrl.split('?')[0]);
            stimulus.id = photoUploadData.data.StimuliGuid;
          } else {
            result.files.push(stimulus.file);
          }
          result.stimuli.push(stimulus);
        } else {
          result.stimuli.push(stimulus);
          result.files.push(stimulus.file);
        }
      } else {
        result.stimuli.push(stimulus);
      }
    }
    return result;
  }

  public getThumbnailUrl(url: string) {
    const extension = url.substring(url.lastIndexOf('.'));
    // the _medium string added to the thumbnailUrl is hardcoded and should be consistent with how ThumbnailService creates thumbnails
    // thumbnail service use png encoding, that's why is hardcoded here
    return url.replace(extension, '_medium.png');
  }

  public async setContributionAccepted(guid) {
    return this.httpservice.post({
      url: '/ConversationService/SetContributionAccepted',
      data: { ConversationGuid: guid },
    });
  }

  updateStimulusAfterNewUpload(stimulus: IUploadConversationStimulus) {
    return this.httpservice.post({
      url: '/ConversationService/UpdateStimulus',
      data: {
        Guid: stimulus.guid,
        Id: stimulus.id,
        Value: stimulus.value,
        Type: stimulus.type,
        Url: stimulus.url,
        ThumbnailUrl: stimulus.thumbnailUrl,
      },
    });
  }

}

export interface ISaveQualReplyAsDraftResponse {
  InDraftConversationStimuli: IConversationStimuli[];
  InDraftConversationAttachments: IConversationAttachment[];
  ConversationGuid: string;
}

interface IStimuliAndFilesResult {
  stimuli: IUploadConversationStimulus[];
  files: File[];
}

interface IAttachmentAndFilesResult {
  attachments: IUploadConversationAttachment[];
  files: File[];
}
